export const Servers = {
  LiveServer: "https://api.upsurgefi.com/",
};

export const GClientId = "407198860917-6jtvr7fkn3skl84pn2p5jq7t8dimckbq.apps.googleusercontent.com";
export const xoxotoken = "eyJ0b2tlbkNvbnRlbnQiOnsiaXNzdWVkRm9yIjoidGVzdCIsInNjb3BlIjoiIiwiaXNzdWVkQXQiOjE2NzYwMjc0ODk0NjYsImV4cGlyZXNBdCI6IjIwMjMtMDItMjVUMTE6MTE6MjkuNDY2WiIsInRva2VuX3R5cGUiOiJVU0VSIn0sImFfdCI6ImV5SmxibU1pT2lKQk1USTRRMEpETFVoVE1qVTJJaXdpWVd4bklqb2lSVU5FU0MxRlV5SXNJbXRwWkNJNkltVnVZeUlzSW1Wd2F5STZleUpyZEhraU9pSkZReUlzSW1OeWRpSTZJbEF0TWpVMklpd2llQ0k2SWkxSmVFdHhkazlJYVdsU2JXeHhUMjV6VFhNemVrRlZXR0pLVFRadk1TMXZNMnRuYVZCaU0xQXhTbU1pTENKNUlqb2lXRWhvYTJGd1JHWnZiVGRQUkhKeVlYUTRMVmRIWDFwcFEyRjBPWEEwWXpKMGFteGtaVkZDTldSdWF5SjlmUS4uVm5fOFRzenFHWUc1NDk0bGVBWkNSZy41S1duZENZeUFPa0Z5eU5yV2NWejlhRjctV0w4WHI5R1ZvcldXclFrc2hyWDhEZzNiRGtSQlZ6enFUdnVWaWkzMFdsX0I4NUhxQ1Q4cVExekJKQVRxeXlBNFpBUjJ1N084YU9zejhhanBGcEhyREFLVVZsMkFiaFAyVEN6SERFM2RPTUJWVzdtVDR0MGdVSm1kLWxrUGoxZ2ZkT1pQbUZUelRPcjBrRnF2NHAwbUhfZDFCVnhxaThpWTNLV21STldKYlVQd1JVMWdyRzVJQk1saDRIZ0JvRkRXOFV5TXVxQTNaWXhXR000Xy1hX1BBaElKNWZ6cnUwWjR1dXlJd2l2b2w5c1BKMXJRU2JkQ2R6SDNHdnRhaXZ0bGg5cGxsUm8wTTVhZ2R6YUZ6MV8wRlRKejV6c0FuRGFia2Z4Zkx0VW1kS3JNMFlKZ3czdTg3ZzJSd3lscFA2YnY3TTB4dTVlY1lBcVJuOXJEMXF0TlByVW4wamt0M3Rob21yYU9WQktlS3J6aUlMeHlOTjRaTHpNejYtR0ZHaEZyaDV3NmREUWhkaVR6cllaeU9VVm0waW5uVEFPVTdwWE5lZUpNZ0RwbklzQ2tEMV9XVC1vLUpfLWg5Tmd2cFZ3VVVFcml5UmtFODd5d2NXTVFLTDU1RWFJM3hYeDFVVHd5ekNwU3BiMUtkc1pUUWhsSUF6UEY1VW5jNGRBOTNLdnhHd0VsWHhkN0VlNHR5QzBLeXpvMzB3NHFadGU5WFVkTW9pX1pDRzhkcFlnUkJfX2JWN1VXdUNTSXNZM3NDVWF0bE9iNEUxNXY2b0J5ejJ6RDVSRVRsMnRhU3VoWi1VZXlXZ2pGN091WV85bzlXN2FIRFFIWU1tcHE0czRLSjM5NXAzbVBIbGJCOGhCSGRWeFNxMWpZX0ZmeXlnQXB1bjRjUzV4TWR4YktOWkI1LWM2R0VyeU5mbnZXMmxkSUczQzNKZk5pd1VWNFViSGlmX180TV85Z2owcmxlWmlpZjVWbnlwMkZxWjlHaGxITTM4VkE5cF90WXI5NlBtYXJJUXk5UHdmMFF5RWZMQ1U0QVpmUnZfMGZ5RjNLNnpfTXUyc1AxakNBSlFsQzhPS20xTzdTMEVHTE5MeFk2VHNIVG0xS19JU1AwU2QtOWlEZzVaMXI2bEZvV1QyTlNDRnRaeGU2ZUcyTW80S1R5LS1laGFZRUg0a0dNZmVNU1Q2dmcuc2RBeWI5MmRmbjVNT0JmT29PWUlNZyJ9";
export const apple_client_id = "";

export const UniCoinValue = 1000;
export const AWS_ACCESS_KEY = "AKIAR2U5IKCYVYJ6RSON";
export const AWS_SECRET_ACCESS_KEY = "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";
export const AWS_BUCKET = "upsurge-public-games";
export const WEBGL_BASE_URL = "https://game-test-liard.vercel.app";

