import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBg1IacwagjjcaDXQiqVyl-nOzLLGaDJGQ",
  authDomain: "upsurge-web-app.firebaseapp.com",
  projectId: "upsurge-web-app",
  messagingSenderId: "407198860917",
  appId: "1:407198860917:web:b005dbf9ae62a07f5eda3c",
};

const app = initializeApp(firebaseConfig);

export default app;
